/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

:root {
  --bg-color: #ffffff;
  --primary-font-color: #000000;
  --secondary-font-color: rgba(51,64,89,1);
  --soft-high-light: rgba(255,255,255,.43);
  --dark-high-light: rgba(217,210,200,.51);
  --neumorphic-shadow-light-one: rgba(60, 64, 67, 0.3);
  --neumorphic-shadow-light-two: rgba(60, 64, 67, 0.15);
}

[data-theme=dark] {
  --bg-color: #131419;
  --primary-font-color: #c7c7c7;
  --secondary-font-color: #03a9f4;
  --soft-high-light: rgba(255,255,255,.05);
  --dark-high-light: rgba(0,0,0,.51);
  --neumorphic-shadow-light-one: rgba(110, 110, 110, 0.4);
  --neumorphic-shadow-light-two: rgba(110, 110, 110, 0.4);
}

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  line-height: 1.65;
  font-size: 1rem;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6,
b, strong,
.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 900;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.text-italic {
  font-style: italic;
}

.text-gray {
  color: #808080;
}

a,
a:link,
a:hover,
a:visited,
a:active,
a:focus {
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

a, button, input
.transition {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

a:hover {
  color: #00bf9a;
}

.mat-horizontal-stepper-header-container {
  pointer-events: none !important;
}

.undefined-list {
  color: darkgray;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
}

.management-container {
  opacity: 0;
  animation: fadeInApp 1s;
  animation-fill-mode: forwards;
  animation-delay: 3.6s;
}

@keyframes fadeInApp {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.app-container {
  margin: 0 10% 0 11.5rem;
}

audio {
  height: 36px;
  margin: 8px 8px 0px 0;
}

/* new style changes  */
.mdc-button, .mdc-fab {
  letter-spacing: normal !important;
}

  .mdc-button:disabled, .mdc-fab:disabled {
    background-color: #e0e0e0 !important;
  }

.mat-mdc-icon-button.mat-mdc-button-base, .mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: 40px;
  height: 40px;
  font-size: 1rem;
  line-height: 1 !important;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
}

.action-checkbox-icon .mdc-checkbox__background {
  width: 0.9rem !important;
  height: 0.9rem !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.mat-mdc-dialog-content {
  padding: 24px !important;
}

.mdc-label {
  margin-bottom: 0 !important;
}

.mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding-bottom: 0 !important;
}

/* custom styles */
.custom-primary-color-btn {
  background-color: #d18a0f !important;
  color: #ffffff !important;
}

.no-error-message .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
  display: none;
}
